img {
    object-fit: contain;
}

hr {
    margin: 3rem 0rem;
}

img.headshot {
    max-height: 300px;
}

.main-info-text {
    padding-left: 1.5rem;
    margin-left: 1.5rem;
}

.main-info-text h1 {
    font-size: 24pt;
}

.main-info-text h2 {
    font-size: 20pt;
    padding-bottom: 0;
    margin-bottom: 0;
}

.main-info-text h3 {
    font-size: 18pt;
}

@media only screen and (max-width: $md) {
    .main-info-text {
        padding-left: 0;
        margin-left: 0;
        text-align: center;
    }

    .social {
        justify-content: center !important;
    }
}

.education p {
    margin-bottom: 0;
    padding-bottom: 0;
}

.work-section {
    text-align: center;
}

.work-card {
    padding: 1rem 3rem;
    margin: inherit;
}

@media only screen and (max-width: $md) {
    .work-card {
        padding: 1rem;
        margin: 1.5rem 1.5rem;
    }
}

.work-link {
    text-align: right !important;
}

a.big-tag {
    font-size: 16pt !important;
    line-height: 28pt !important;
    border: none;
}

.small-img {
    max-width: 350px !important;
}