@charset "utf-8";
// 
// This is adapted from the "minima" theme  
// to allow the user to easily override 
// theme styels likes color and spacing

// Define defaults for each variable.

// bootstrap screen sizes
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
// Notice the following mixin uses max-width, in a deprecated, desktop-first
// approach, whereas media queries used elsewhere now use min-width.
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: #{$ratio}rem;
}

// Import pre-styling-overrides hook and style-partials.
@import
  "cadre/variables",        // Default settings
  "cadre/custom-variables", // Hook to override predefined variables.
  "cadre/main",             // Loads specific styles
  "cadre/custom-styles"     // Hook to override predefined styles.
;