.post {
    padding-right: 25px;
    background-color: $post-bg;
}

.post-body {
    background-color: inherit;
}

.post-header {
    padding: 10px 0px; 
}

.post-metadata {
    color: $metadata-color;
}

.footnotes {
  overflow: auto;
  word-wrap: break-word;
  border-top: 1px solid lightgrey;
  padding-top: 25px; 
}

hr {
    margin-top: 5px; 
    margin-bottom: 5px;
}
