.toc-title {
  font-size: 16px;
  text-align: left;
}

.toc-div {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: calc(#{$top-nav-space} + #{$sticky-div-pad}) !important; 
}

.toc,
.toc ul {
  font-size: 12px;
  list-style: none;
  padding: 0;
}

.toc {
  max-height: 80vh;
  overflow-y: auto;

  a.active {
    color: $site-secondary;
  }

  a {
    display: block;
    text-decoration: none;

    color: $toc-text-color;

    &:hover,
    &:focus {
      font-weight: bold;
      color: $link-hover-color !important;
    }
  }

  // indentations 

  li > a {
    padding-left: .5em;
  }
  ul li > a {
    padding-left: 1.5em;
  }
  ul li ul li > a {
    padding-left: 2.5em;
  }
  ul li ul li ul li > a {
    padding-left: 3em;
  }
  ul li ul li ul li ul li > a {
    padding-left: 3.5em;
  }
}

@media only screen and (max-width: $md) {
  h3.toc-title {
    text-align: center;
  }
  .toc {
    padding-left: 15px !important;
  }
}
