@charset "utf-8";
// pad main container to account for fixed topnav
$top-nav-space: 65px;

// additional space from top-nav-space before sticky divs
$sticky-div-pad: 50px;

// base nav logo image size
$navbar-img-height: 45px; 
$navbar-img-width: 150px; 

// colors
$tan: rgb(255, 246, 231);
$beige: rgb(255, 248, 238);
$cream: rgb(248, 247, 245);

$site-primary: rgb(214, 12, 12);
$site-secondary: rgb(119, 0, 0);
$site-tertiary: var(--oc-gray-6);
$body-bg: $cream;
$math-bg: $beige;
$nav-bg: var(--oc-white);
$footer-bg: var(--oc-gray-1);
$card-bg: var(--oc-white);
$code-bg: var(--oc-gray-1);
$post-bg: var(--oc-white);

$link-hover-color: $site-primary;
$link-color: $site-secondary;

$h1-color: var(--oc-gray-7);
$h2-color: var(--oc-gray-7);
$h3-color: var(--oc-gray-7);
$p-color: var(--oc-black);
$metadata-color: var(--oc-gray-6);
$toc-text-color: var(--oc-gray-6);

$body-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
$body-font-size: 16px;

$brand-font-size: 1.25rem;
$brand-font-size-small: 1.1rem;

$border-radius: .4em;

$heading-font: Georgia, "Times New Roman", Times, "Palatino Linotype", "Book Antiqua", Palatino, serif;
$code-font: "Source Code Pro", Menlo, "Lucida Console", Monaco, "Courier New", Courier, monospace;

$spacer: 1rem;
