.navbar {
  padding-left: 10%;
  padding-right: 10%;
}

.navbar-brand {
  color: $site-primary !important;
}

.navbar-brand img {
  margin-top: -10px;
  transition: all 0.25s ease-in-out;
}

a.navbar-brand {
  transition: all 0.25s ease-in-out;
}

.scrolling {
  font-size: $brand-font-size-small;
}

.top {
  font-size: $brand-font-size;
}

.scrolling img {
  // width: unquote("#{$navbar-img-width} * 0.9"px);
  // height: unquote("#{$navbar-img-height} * 0.9"px);
  width: calc(#{$navbar-img-width} * 0.9);
  height: calc(#{$navbar-img-height} * 0.9);
}

.top img {
  width: $navbar-img-width;
  height: $navbar-img-height;
}

a.nav-link {
  color: $site-tertiary;
}

#menu {
  background-color: $nav-bg;
}

@media only screen and (max-width: $md) {
  .scrolling {
    width: calc(#{$navbar-img-width} * 0.55);
    height: calc(#{$navbar-img-height} * 0.55);
  }

  .top {
    width: calc(#{$navbar-img-width} * 0.65);
    height: calc(#{$navbar-img-height} * 0.65);
  }

  // override the max-height setting to ensure 
  // image scales appropriately 
  .scrolling img {
    max-height: none;
  }

  .top img {
    max-height: none;
  }
}