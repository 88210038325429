.highlight table td { padding: 5px; }
.highlight table pre { margin: 0; }
.highlight, .highlight .w {
  color: #282828;
  // background-color: #fbf1c7;
  background-color: #f3f3f3;
}
.highlight .nf {
  // color: rgb(112, 179, 233);
  color: #1f80ff;
}
.highlight .err {
  color: #9d0006;
  // background-color: #fbf1c7;
  font-weight: bold;
}
.highlight .c, .highlight .ch, .highlight .cd, .highlight .cm, .highlight .cpf, .highlight .c1, .highlight .cs {
  color: #9c9c9c;
  font-style: italic;
}
.highlight .cp {
  color: #427b58;
}
.highlight .nt {
  color: #9d0006;
}
.highlight .o, .highlight .ow {
  color: #282828;
}
.highlight .p, .highlight .pi {
  color: #282828;
}
.highlight .gi {
  color: #79740e;
  background-color: #fbf1c7;
}
.highlight .gd {
  color: #9d0006;
  background-color: #fbf1c7;
}
.highlight .gh {
  color: #79740e;
  font-weight: bold;
}
.highlight .k, .highlight .kn, .highlight .kp, .highlight .kr, .highlight .kv {
  // color: #9d0006;
  color: #c50c3a;
}
.highlight .kc {
  color: #8f3f71;
}
.highlight .kt {
  // color: #b57614;
  color: #96743e;
}
.highlight .kd {
  color: #af3a03;
}
.highlight .s, .highlight .sa, .highlight .sb, .highlight .sc, .highlight .dl, .highlight .sd, .highlight .s2, .highlight .sh, .highlight .sx, .highlight .s1 {
  color: #79740e;
  font-style: italic;
}
.highlight .si {
  color: #79740e;
  font-style: italic;
}
.highlight .sr {
  color: #79740e;
  font-style: italic;
}
.highlight .se {
  color: #af3a03;
}
.highlight .nn {
  color: #427b58;
}
.highlight .nc {
  // color: #427b58;
  color: rgb(0, 94, 170);
}
.highlight .no {
  color: #8f3f71;
}
.highlight .na {
  color: #79740e;
}
.highlight .m, .highlight .mb, .highlight .mf, .highlight .mh, .highlight .mi, .highlight .il, .highlight .mo, .highlight .mx {
  color: #8f3f71;
}
.highlight .ss {
  color: #076678;
}
